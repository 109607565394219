import { PlayArrow } from '@material-ui/icons'
import React from 'react'
import "./contact.scss"
import {useState} from "react";
import {sendMail} from '../sendEmail'
import emailjs from 'emailjs-com';


function Contact() {

    const [message, setMessage] = useState(false);
    const handleSubmit = (e)=>{
        e.preventDefault();
        setMessage(true);

    }
    return (
        <div className="contact" id="contact">
            <div className="left">
                <img src="/assets/contact.jpg" alt=""/>
            </div>
            <div className="right">
                <h2>Contact</h2>
                <form onSubmit={handleSubmit}>
                    <input type="text" id="text" placeholder="Your Email"/>
                    <textarea placeholder="Message" id = "Message"></textarea>
                    <button type="submit" onClick={sendMail}>Send</button>
                    {message && <span>Thank you for contacting me! I will get back to you as soon as possible.</span>}
                </form>
                <script src="https://cdn.jsdelivr.net/npm/emailjs-com@3/dist/email.min.js"></script>
                <script>
                    (function() {
                    emailjs.init("user_iBFcCYKXlh8PD3PieMPgy")})();
                </script>
                    

            </div>
        </div>
    )
}

export default Contact
