import React from 'react'
import "./intro.scss"
import {KeyboardArrowDown} from "@material-ui/icons"
import {init} from 'ityped'
import {useEffect, useRef} from "react"


function Intro() {
    const textRef = useRef();

    useEffect(()=>{
        init(textRef.current,{
            showCursor:true,
            backDelay: 1500,
            backSpeed: 75,
            strings: ["Software Developer", "Full Stack Engineer", "Learner"],
            startDelay:750,
        });
    },[])
    return (
        <div className="intro" id="intro">
            <div className="left">
                <div className="imgContainer">
                    <img src="assets/cropped.jpg" alt=""/>
                </div>
            </div>

            <div className="right">
                <div className="wrapper">
                    <h2>Hi! I'm</h2>
                    <h1>David Bichara</h1>
                    <h3>Passionate <span ref={textRef}></span></h3>
                    <p className="about">Excited about changing society through moving Bytes</p>
                </div>
                <a href="#portfolio">
                    <li>Portfolio</li>
                    <li><KeyboardArrowDown className="icon"/></li>
                </a>
                </div>
        </div>
    )
}

export default Intro
