import React from 'react'
import "./skills.scss"
import SettingsIcon from '@material-ui/icons/Settings';
import BuildIcon from '@material-ui/icons/Build';
import {KeyboardArrowDown} from "@material-ui/icons"

function Skills() {
    return (
        <div className="skillMain" id="skills">
            <h1>Skills</h1>
            <div className="container">
                 <div className="left">
                    <h2>Languages</h2>
                    <ul>
                        <li>
                            <h3><SettingsIcon fontSize="small" className="icon"/>Java</h3>
                        </li>
                        <li>
                            <h3><SettingsIcon fontSize="small" className="icon"/>Python</h3>
                        </li>
                        <li>
                            <h3><SettingsIcon fontSize="small" className="icon"/>C++</h3>
                        </li>
                        <li>
                            <h3><SettingsIcon fontSize="small" className="icon"/>C</h3>
                        </li>
                        <li>
                            <h3><SettingsIcon fontSize="small" className="icon"/>JavaScript</h3>
                        </li>
                        <li>
                            <h3><SettingsIcon fontSize="small" className="icon"/>TypeScript</h3>
                        </li>
                        <li>
                            <h3><SettingsIcon fontSize="small" className="icon"/>SQL</h3>
                        </li>
                        <li>
                            <h3><SettingsIcon fontSize="small" className="icon"/>Bash</h3>
                        </li>
                        <li>
                            <h3><SettingsIcon fontSize="small" className="icon"/>HTML</h3>
                        </li>
                        <li>
                            <h3><SettingsIcon fontSize="small" className="icon"/>CSS</h3>
                        </li>
                        <li>
                            <h3><SettingsIcon fontSize="small" className="icon"/>MATLAB</h3>
                        </li>
                        <li>
                            <h3><SettingsIcon fontSize="small" className="icon"/>XML</h3>
                        </li>
                        <li>
                            <h3><SettingsIcon fontSize="small" className="icon"/>Simulink</h3>
                        </li>
                    </ul>
                 </div>
                 <div className="right">
                    <h2>Tools &amp; Technologies</h2>
                    <ul>
                        <li>
                            <h3><BuildIcon className="icon"/>REST API</h3>
                        </li>
                        <li>
                            <h3><BuildIcon className="icon"/>AWS</h3>
                        </li>
                        <li>
                            <h3><BuildIcon className="icon"/>JPA</h3>
                        </li>
                        <li>
                            <h3><BuildIcon className="icon"/>Spring Framework</h3>
                        </li>
                        <li>
                            <h3><BuildIcon className="icon"/>React.js</h3>
                        </li>
                        <li>
                            <h3><BuildIcon className="icon"/>Next.js</h3>
                        </li>
                        <li>
                            <h3><BuildIcon className="icon"/>Kafka</h3>
                        </li>
                        <li>
                            <h3><BuildIcon className="icon"/>Cucumber</h3>
                        </li>
                        <li>
                            <h3><BuildIcon className="icon"/>Git</h3>
                        </li>
                        <li>
                            <h3><BuildIcon className="icon"/>Maven</h3>
                        </li>
                        <li>
                            <h3><BuildIcon className="icon"/>Docker</h3>
                        </li>
                        <li>
                            <h3><BuildIcon className="icon"/>Jenkins</h3>
                        </li>
                        <li>
                            <h3><BuildIcon className="icon"/>JavaFX</h3>
                        </li>
                        <li>
                            <h3><BuildIcon className="icon"/>SonarQube</h3>
                        </li>

                    </ul>
                 </div>
            </div>
            <div className="play">
            <a href="#play">
                <li>Play</li>
                <li><KeyboardArrowDown className="icon"/></li>
            </a> 
    </div>
        </div>
    )
}

export default Skills


