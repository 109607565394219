import emailjs from 'emailjs-com';
//init("user_iBFcCYKXlh8PD3PieMPgy");

export function sendMail(params) {
    var tempParams = { 
        from_name: document.getElementById('text').value,
        to_name: "David Bichara",
        message: document.getElementById('Message').value

    };

    emailjs.send('service_ehmf1qt', 'template_3x9t4vq', tempParams)
    .then(function(res){
        console.log("success", res.status)
    })
    //'service_ehmf1qt
}

export default sendMail