import React from 'react'
import { useState } from 'react';
import "./play.scss"
import {KeyboardArrowDown, GitHub} from "@material-ui/icons"
import * as Timer from 'timer.js';



var playerArr = ['A', 'B', 'C', 'D', 'E', 'F'];
var submitArr = [ 'F', 'G', 'H', 'I', 'J', 'K'];
var alphabetNoVowels = ['B', 'C', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'V', 'W', 'X', 'Y', 'Z'];
var vowels = ['A', 'E', 'I', 'O', 'U'];
var indexSubmit = 0;
var exceuted = false;
var run = false;



function Play() {
    const [playZero, setPlayZero] = useState(' ');
    const [playOne, setPlayOne] = useState(' ');
    const [playTwo, setPlayTwo] = useState(' ');
    const [playThree, setPlayThree] = useState(' ');
    const [playFour, setPlayFour] = useState(' ');
    const [playFive, setPlayFive] = useState(' ');
    const [submitZero, setSubmitZero] = useState(' ');
    const [submitOne, setSubmitOne] = useState(' ');
    const [submitTwo, setSubmitTwo] = useState(' ');
    const [submitThree, setSubmitThree] = useState(' ');
    const [submitFour, setSubmitFour] = useState(' ');
    const [submitFive, setSubmitFive] = useState(' ');

    const [score, setScore] = useState(0);
    const [time, setTime] = useState(60);
    var finalWord = ' ';
    var myInt;
    let timer = 60;



    const init = () =>
    {
        if(exceuted == true){
            return
        }
        for(let i =0; i<playerArr.length; i = i +2){
            playerArr[i] = alphabetNoVowels[parseInt(Math.random() * 21, 10)];
        }
        playerArr[1] = vowels[parseInt(Math.random() * 5, 10)];
        playerArr[3] = vowels[parseInt(Math.random() * 5, 10)];
        setPlayZero(playerArr[0]);
        setPlayOne(playerArr[1]);
        setPlayTwo(playerArr[2]);
        setPlayThree(playerArr[3]);
        setPlayFour(playerArr[4]);
        setPlayFive(playerArr[5]);
        exceuted = true;
    }



    const moveTileZero = () => {
        var indexFrom = 0;
        [submitArr[indexSubmit]] = [playerArr[indexFrom]];
        if(indexSubmit == 0){
            setSubmitZero(submitArr[indexSubmit]);
        }
        if(indexSubmit == 1){
            setSubmitOne(submitArr[indexSubmit]);
        }
        if(indexSubmit == 2){
            setSubmitTwo(submitArr[indexSubmit]);
        }
        if(indexSubmit == 3){
            setSubmitThree(submitArr[indexSubmit]);
        }
        if(indexSubmit == 4){
            setSubmitFour(submitArr[indexSubmit]);
        }
        if(indexSubmit == 5){
            setSubmitFive(submitArr[indexSubmit]);
        }
        indexSubmit++;
    
    }

    const moveTileOne = () => {
        var indexFrom = 1;
        [submitArr[indexSubmit]] = [playerArr[indexFrom]];
        if(indexSubmit == 0){
            setSubmitZero(submitArr[indexSubmit]);
        }
        if(indexSubmit == 1){
            setSubmitOne(submitArr[indexSubmit]);
        }
        if(indexSubmit == 2){
            setSubmitTwo(submitArr[indexSubmit]);
        }
        if(indexSubmit == 3){
            setSubmitThree(submitArr[indexSubmit]);
        }
        if(indexSubmit == 4){
            setSubmitFour(submitArr[indexSubmit]);
        }
        if(indexSubmit == 5){
            setSubmitFive(submitArr[indexSubmit]);
        }
        indexSubmit++;
    
    }
    const moveTileTwo = () => {
        var indexFrom = 2;
        [submitArr[indexSubmit]] = [playerArr[indexFrom]];
        if(indexSubmit == 0){
            setSubmitZero(submitArr[indexSubmit]);
        }
        if(indexSubmit == 1){
            setSubmitOne(submitArr[indexSubmit]);
        }
        if(indexSubmit == 2){
            setSubmitTwo(submitArr[indexSubmit]);
        }
        if(indexSubmit == 3){
            setSubmitThree(submitArr[indexSubmit]);
        }
        if(indexSubmit == 4){
            setSubmitFour(submitArr[indexSubmit]);
        }
        if(indexSubmit == 5){
            setSubmitFive(submitArr[indexSubmit]);
        }
        indexSubmit++;
    
    }
    const moveTileThree = () => {
        var indexFrom = 3;
        [submitArr[indexSubmit]] = [playerArr[indexFrom]];
        if(indexSubmit == 0){
            setSubmitZero(submitArr[indexSubmit]);
        }
        if(indexSubmit == 1){
            setSubmitOne(submitArr[indexSubmit]);
        }
        if(indexSubmit == 2){
            setSubmitTwo(submitArr[indexSubmit]);
        }
        if(indexSubmit == 3){
            setSubmitThree(submitArr[indexSubmit]);
        }
        if(indexSubmit == 4){
            setSubmitFour(submitArr[indexSubmit]);
        }
        if(indexSubmit == 5){
            setSubmitFive(submitArr[indexSubmit]);
        }
        indexSubmit++;
    
    }
    const moveTileFour = () => {
        var indexFrom = 4;
        [submitArr[indexSubmit]] = [playerArr[indexFrom]];
        if(indexSubmit == 0){
            setSubmitZero(submitArr[indexSubmit]);
        }
        if(indexSubmit == 1){
            setSubmitOne(submitArr[indexSubmit]);
        }
        if(indexSubmit == 2){
            setSubmitTwo(submitArr[indexSubmit]);
        }
        if(indexSubmit == 3){
            setSubmitThree(submitArr[indexSubmit]);
        }
        if(indexSubmit == 4){
            setSubmitFour(submitArr[indexSubmit]);
        }
        if(indexSubmit == 5){
            setSubmitFive(submitArr[indexSubmit]);
        }
        indexSubmit++;
    
    }
    const moveTileFive = () => {
        var indexFrom = 5;
        [submitArr[indexSubmit]] = [playerArr[indexFrom]];
        if(indexSubmit == 0){
            setSubmitZero(submitArr[indexSubmit]);
        }
        if(indexSubmit == 1){
            setSubmitOne(submitArr[indexSubmit]);
        }
        if(indexSubmit == 2){
            setSubmitTwo(submitArr[indexSubmit]);
        }
        if(indexSubmit == 3){
            setSubmitThree(submitArr[indexSubmit]);
        }
        if(indexSubmit == 4){
            setSubmitFour(submitArr[indexSubmit]);
        }
        if(indexSubmit == 5){
            setSubmitFive(submitArr[indexSubmit]);
        }
        indexSubmit++;
    
    }

    const clearZero = () =>{
        if(submitOne != ' '){
            alert("Tiles can only be removed in the order they were placed!")
            return;
        }
        setSubmitZero(' ')
        submitArr[0] = ' ';
        indexSubmit--;
    }

    const clearOne = () =>{
        if(submitTwo != ' '){
            alert("Tiles can only be removed in the order they were placed!")
            return;
        }
        setSubmitOne(' ')
        submitArr[1] = ' ';
        indexSubmit--;
    }
    const clearTwo = () =>{
        if(submitThree != ' '){
            alert("Tiles can only be removed in the order they were placed!")
            return;
        }
        setSubmitTwo(' ')
        submitArr[2] = ' ';
        indexSubmit--;
    }
    const clearThree = () =>{
        if(submitFour != ' '){
            alert("Tiles can only be removed in the order they were placed!")
            return;
        }
        setSubmitThree(' ')
        submitArr[3] = ' ';
        indexSubmit--;
    }
    const clearFour = () =>{
        if(submitFive != ' '){
            alert("Tiles can only be removed in the order they were placed!")
            return;
        }
        setSubmitFour(' ')
        submitArr[4] = ' ';
        indexSubmit--;
    }
    const clearFive = () =>{
        setSubmitFive(' ')
        submitArr[5] = ' ';
        indexSubmit--;
    }

    const onSubmit = () => {
        finalWord = submitZero + submitOne + submitTwo + submitThree + submitFour + submitFive;
        const url='https://davidbcorsproxy.herokuapp.com/https://od-api.oxforddictionaries.com/api/v2/entries/en-gb/'+ finalWord.replace(/\s/g,'').toLowerCase() +'?fields=definitions%2Cdomains%2Cetymologies&strictMatch=true';
        //https://cors-anywhere.herokuapp.com/
        var ressss = fetch(url, {
            method: 'GET', 
            headers: {
                    "Accept": "application/json",
                    "app_id": "0b0df389",
                    "app_key": "45bb5d0cc70d3765c7470338f679778a"
                  },
                  mode:'cors',
                  cache: 'default',
            })
            .then(response => {
                if (response.ok) {
                    correctWord();
                    return response.json()
                  } else if(response.status === 404) {
                      wrongWord();
                    return Promise.reject('error 404')
                    console.log("404");
                  }
                  else if(response.status === 429) {
                    alert("The Oxford Dictionary API has reached its rate limit from this website")
                  return Promise.reject('error 429')
                  console.log("429");
                }
            })
            .then(data => data)
            .then(result => {
                if (!result.ok) throw result;
                    return result.json();
            })
            clearSubmit();
    }

    const correctWord = () => {    
        setScore(parseInt(finalWord.replace(/\s/g,'').length + "0" + "0") + parseInt(score));
       console.log(parseInt(finalWord.replace(/\s/g,'').length + "0" + "0"));
       document.getElementById("score").classList.add("wrong");

    }
    const wrongWord = () => {
    }

    const startTimer = () => {
        myInt = setInterval(function () {
            setTime(timer);
            timer--;
            if(timer < -1){
                alert("GAME OVER :(");
                setTime(0);
                clearInterval(myInt);
                run = false;
            }
        }, 1000);
        
    }

    const replay = () => {
        if(run == true){
            alert("You cannot start a game while one is in progress!");
            timer = 60;
            return;
        }
        exceuted = false;
        init();
        setScore('0');
        clearSubmit();
        indexSubmit = 0;
        timer = 60;
        startTimer();
        console.log(run);
        run = true;
        

    }


    const clearSubmit = () => {
        setSubmitZero(' ');
        setSubmitOne(' ');
        setSubmitTwo(' ');
        setSubmitThree(' ');
        setSubmitFour(' ');
        setSubmitFive(' ');
        for(let i = 0; i<submitArr.length; i++){
            submitArr[i] = ' ';
        }
        indexSubmit = 0;
    }

    return (
        <div className="playdiv" id="play">
            <h1>Play!</h1>
            <div className="gamebox" id="gamebox">
                <h2>Anagrams</h2>
            <div className="scoreTimer">
                <h3 className= "score" id="score">Score: <span>{score}</span></h3>
                <h3 className="timer">Timer: <span>{time}</span> seconds</h3>
            </div>
            <div className="submitBlocks">
                <button className= "Btn" onClick={replay}>Play</button>
                <h1 className="submit" onClick={clearZero}>{submitZero}</h1>
                <h1 className="submit" onClick={clearOne}>{submitOne}</h1>
                <h1 className="submit" onClick={clearTwo}>{submitTwo}</h1>
                <h1 className="submit" onClick={clearThree}>{submitThree}</h1>
                <h1 className="submit" onClick={clearFour}>{submitFour}</h1>
                <h1 className="submit" onClick={clearFive}>{submitFive}</h1>
                <button className= "Btn" onClick={onSubmit}>Submit</button>
            </div>
            <div className="playBlocks">
                <h1 className="play" onClick={moveTileZero}>{playZero}</h1>
                <h1 className="play" onClick={moveTileOne}>{playOne}</h1>
                <h1 className="play" onClick={moveTileTwo}>{playTwo}</h1>
                <h1 className="play" onClick={moveTileThree}>{playThree}</h1>
                <h1 className="play" onClick={moveTileFour}>{playFour}</h1>
                <h1 className="play" onClick={moveTileFive}>{playFive}</h1>
            </div>
            <div className="instructions">
            <h3><h2>Instructions: </h2>Press Play! Create valid english words by clicking on the bottom boxes, press submit to check your word and watch your score increase! Deletions in the top boxes can be done by simply clicking the boxes in the order they were placed.</h3>
            </div>
            <div className="copyright">
                <p className="created"><a href='https://github.com/davidbichara/developerWebsite' target="_blank"><GitHub className="git"></GitHub></a>David Bichara 2021 </p>
            </div>
            </div>
            <div className="contact">
            <a href="#contact">
                <li>Contact</li>
                <li><KeyboardArrowDown className="icon"/></li>
            </a> 
    </div>
        </div>

    )
}

export default Play
