import React from 'react'
import "./topbar.scss"
import {GitHub, Attachment, LinkedIn} from "@material-ui/icons"

function Topbar({menuOpen, setMenuOpen}) {
    return (
        <div className={"topbar "+ (menuOpen && "active")}>
            <div className="wrapper">
                <div className="left">
                    <a href="#intro" className="logo">David Bichara</a>
                    <a href="https://github.com/davidbichara" target="_blank">
                    <div className="itemContainer">
                        <GitHub className="icon"/>
                        <span>GitHub</span>
                    </div>
                    </a>
                    <a href="https://www.linkedin.com/in/david-bichara/" target="_blank">
                    <div className="itemContainer">
                        <LinkedIn className="icon"/>
                        <span>LinkedIn</span>
                    </div>
                    </a>
                    <a href="assets\Bichara, David_Resume.pdf" target="_blank">
                    <div className="itemContainer">
                        <Attachment className="icon"/>
                        <span>Resume</span>
                    </div>
                    </a>
                </div>
                <div className="right">
                    <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
                        <span>line 1</span>
                        <span>line 2</span>
                        <span>line 3</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Topbar
