export const JPM = [
    {
        id: 0,
        divName: "jpm",
        title: "JPMorgan Chase",
        role: "Software Engineer Intern",
        published: "Jun 2022 - Present",
        text: "I worked in the consumer banking line of business at JPMorgan Chase. My team and I worked on the central Data Pipeline product at the firm which processes and stores over $2 Trillion dollars in consumer payments a day in addition to mortgage and credit data. On this product, I implemented a contract testing framework to scale to all microservice applications on the Chases' central data pipeline. I created additional APIs and functionality that allowed for other microservices to provision metadata regarding datasets. Additionally, I built a new microservice to create and maintain logs for cascade deletion and editing using Java, Spring Boot, and Kafka. Overall, I walked away from this experience with a solid understanding of how to create secure, scalable, and highly functional internal applications. ",
        img: "assets/jpm.png",
        tech: "Java, Spring Boot, Kafka, Maven, Jenkins, Git",
        skills: "Microservice Architecture, System Design, Contract Testing",
        link: "https://www.jpmorganchase.com/",
    },
]

export const MITPortfolio = [
    {
        id: 1,
        divName: "mit",
        title: "Massachusetts Institute of Technology",
        role: "Machine Learning Research Engineer",
        published: "Jan 2022 - Present",
        text: "I joined the Teaching Systems Lab at the Massachusetts Institute of Technology to apply my knowledge of programming, modeling, and Machine Learning to address the hardest problems facing engineers. At MIT, my team and I created a supervised machine learning model which could detect confusion in audio with 70%+ Validation Precision. I was able to achieve a 10% increase in validation precision by adding features which stratified the audio clips by gender using the XGB Classifer and Python. I was able to do this by utilizing feature extraction and data segmentation in Python by using Parselmouth, PyTorch, and Pandas to increase the dimensionality of the model. ",
        img: "assets/MIT.png",
        tech: "Python, Anaconda, Parselmouth, PyTorch, Pandas",
        skills: "Machine Learning, Supervised Learning, Classifer Creation, Model Analysis",
        link: "https://tsl.mit.edu/team/david-bichara/",
    },
]

export const CSLibPortfolio = [
    {
        id: 2,
        divName: "cslib",
        title: "CompSciLib",
        role: "Co-Founder & Full Stack Engineer",
        published: "Nov 2021 - Present",
        text: "CompSciLib is an Educational Technology Platform I co-founded with the aim of making computer science easier to learn. This platform provides a compilation of calculation resources, professionally written explanations, and examples to allow students to reach their goals. On this project I served as a Full-Stack Engineer implementing the model–view–controller design pattern to dynamically send data, front-end resources, and calculation results from the back end to the front end. In addition to writing basic back-end code to perform calculations, I architected 40+ API endpoints in Java, Spring Boot, and Maven.These endpoints allow for users to receive information and perform calculations through the front-end. Additionally, I used TypeScript, HTML, CSS, React.js, and Next.js to build a dynamic and scalable front-end. Using AWS my team and I were able to release the Web Application to over 4,000 users.",
        img: "assets/CompSciLib.jpg",
        tech: "Java, Spring Boot, AWS, Maven, TypeScript, HTML, CSS, React.js, and Next.js",
        skills: "Web Application Development, Platform Development, Service-Architecture",
        link: "https://www.compscilib.com/",
    },
]

export const squarePlayPortfolio = [
    {
        id: 3,
        divName: "squareplay",
        title: "Square Play App",
        role: "Co-Fullstack Developer & Co-founder",
        published: "Jan 2021 - February 2021",
        text: "Square Play is a unique boundless recreational board game currently available on the Google Play Store. During the development of this App, I was primarily responsible for the Back End and created logic to keep track of board pieces, initiate player moves, and alter game difficulty. I did this while maintaining efficient runtime complexity and implementing many types of Data Structures ranging from simple Arrays to LinkedHashMaps and TreeMaps. The game was developed in 3 weeks and is being continually maintained by my collaborator and me to this day. I feel free to check it out by clicking on the Square Play App logo!",
        img: "assets/SquarePlay.webp",
        tech: "Java, XML, Maven, Git, Andriod Studio",
        skills: "Game Development, Mobile App Development",
        link: "https://play.google.com/store/apps/details?id=squareplay.com&hl=en_US&gl=US",
    },
]

export const oneTrustPortfolio = [
    {
        id: 4,
        divName: "onetrust",
        title: "OneTrust",
        role: "Java Developer Intern",
        published: "May 2021 - Jul 2021",
        text: "At OneTrust I was able to gain valuable experience working on a unique Cloud and On-Premise hybrid microservice architecture. In my time at OneTrust, I worked with my Scrum team to advance the Data Discovery product by completing five features (one shown in OneTrust internal demo), one bugfix, 10 tasks, and two documentation pages in two, three-week Agile development cycles. The aforementioned features and bug fixes I Implemented are currently being used by thousands of OneTrust Customers. Towards the end of my tenure, I led meetings with Product Managers, Dev Leads, and Senior Managers to try to collaborate to solve an issue regarding the intensive coupling of two microservices.",
        img: "assets/OT.jpg",
        tech: "Java, SQL, Spring Boot, JPA, REST, Maven, Docker, Jenkins, Bash, SonarQube",
        skills: "Enterprise Software Development, Cloud Software Development, Web Microservice Architecture, Databasing Technologies",
        link: "https://www.onetrust.com/blog/onetrust-inc-500/",
    },
]

export const twitterPortfolio = [
    {
        id: 5,
        divName: "twitter",
        title: "Twitter Study",
        role: "Primary Investigator",
        published: "Aug 2020 - Jul 2021",
        text: "My team and I set out to discover how COVID-19 related research Tweets can be analyzed to gauge public engagement with nature of science (NOS), a critical component of scientific literacy. By analyzing how everyday people interacted with aspects of NOS in the early months of the pandemic, we were able to uncover interesting trends regarding NOS concepts. To perform this analysis, I developed software in Python and MATLAB to streamline qualitative research coding and discover patterns in the data. Additionally, as a member of this research team, I contributed by reviewing relevant literature, conceptualizing and conducting data analysis, and scientific writing. The manuscript which was published in Science & Education is accessible by clicking on the Twitter Icon to the right!",
        img: "assets/twitter-logo.jpg",
        tech: "Python, MATLAB",
        skills: "Research, Research Software Development, UI Development",
        link: "https://link.springer.com/article/10.1007/s11191-021-00233-y",
    },
]

export const MNLPortfolio = [
    {
        id: 6,
        divName: "mnl",
        title: "Mechanical Neuroimaging Lab",
        role: "Research Assistant & Summer Scholar under Dr. Curtis L. Johnson",
        published: "June 2020 - Jan 2021",
        text: "As a Freshman in college, I was given a $4000 stipend from the Undergraduate Research program to perform an individual project focusing on modeling and localizing subject motion in Magnetic Resonance Elastography (MRE). During my Freshman summer, I was able to create a MATLAB model to detect subject movement during an MRE scan. This MATLAB model achieved a 100% rate of detecting subject movement by utilizing multidimensional Matrix manipulation to detect movement in voxels during temporal repetitions typically taken in MRE. My colleagues and I are continuing to work on developing a holistic solution in localizing movement. Click on the brain to the right to view my poster presentation on the project from the summer of 2020!",
        img: "assets/MNL-logo (1).png",
        tech: "MATLAB, Bash, Cluster Server",
        skills: "Mathematical Modeling, Clinical Research, Mutidimensional Matrix Manipulation",
        link: "assets/Bichara_MNL.pdf",
    },
]

export const Anagrams = [
    {
        id: 7,
        divName: "anagrams",
        title: "Anagrams Web App",
        role: "Full Stack Developer & Creator",
        published: "July 2021 - July 2021",
        text: "While building this website with React, JavaScript, HTML, and CSS, I decided to create Anagrams, my favorite game. I created the game logic from scratch in JavaScript and used React, HTML, and CSS to create and display elements. To check if the entered word is valid, I send 'get' requests to the Oxford Dictionary API through a proxy server I created and handle their responses. Building this web app allowed me to continue developing my skills in the aforementioned tools as well as in RESTful API. Give it a try by clicking on Play in the hamburger menu above. ",
        img: "assets/scrabble.jpg",
        tech: "JavaScript, React, HTML, CSS, RESTful",
        skills: "Web App Development, Game Development, REST API",
        link: "#play",
    },
]


