import Contact from "./components/Contact";
import Intro from "./components/Intro";
import Portfolio from "./components/Portfolio";
import Play from "./components/Play";
import Topbar from "./components/Topbar";
import Skills from "./components/Skills";
import "./app.scss";
import {useState} from "react";
import Menu from "./components/Menu"
import { PlayArrow } from "@material-ui/icons";

function App() {
const [menuOpen, setMenuOpen] = useState(false)

  return (
    <div className="app">
       <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
       <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
       <div className="sections">
         <Intro/>
         <Portfolio/>
         <Skills/>
         <Play function onClick="Jump()"/>
         <Contact/>
       </div>
    </div>
  );
}

export default App;
