import React from 'react'
import "./portfolio.scss"
import PortfolioList from "./PortfolioList"
import {useState, useEffect} from "react";
import {MITPortfolio, CSLibPortfolio, squarePlayPortfolio, oneTrustPortfolio, twitterPortfolio, MNLPortfolio, Anagrams, JPM} from "../data.js"
import {KeyboardArrowDown} from "@material-ui/icons"
import LinkIcon from '@material-ui/icons/Link';

function Portfolio() {
    const [selected, setSelected] = useState("JPM");
    const [data, setData] = useState([]);
    
    const list = [
        {
            id: "JPM",
            title: "JPMorgan Chase",
        },
        {
            id: "Massachusetts Institute of Technology",
            title: "MIT",
        },
        {
            id: "CompSciLib",
            title: "CompSciLib",
        },
        {
            id: "OneTrust",
            title: "OneTrust",
        },
     {
        id: "Square Play App",
        title: "Square Play App",
    },
    {
        id: "Anagrams Web App",
        title: "Anagrams Web App",
    },
    {
        id: "Twitter Study",
        title: "Twitter Study",
    },
    {
        id: "Mechanical Neuroimaging Lab",
        title: "Mechanical Neuroimaging Lab",
    },   
    ];

    useEffect(() => {

        switch(selected){
            case "JPM":
                setData(JPM);
                break;
            case "Massachusetts Institute of Technology":
                setData(MITPortfolio);
                break;
            case "CompSciLib":
                setData(CSLibPortfolio);
                break;
            case "Square Play App":
                setData(squarePlayPortfolio);
                break;
            case "OneTrust":
                setData(oneTrustPortfolio);
                break;
            case "Twitter Study":
                setData(twitterPortfolio);
                break;    
            case "Anagrams Web App":
                setData(Anagrams);
                break;
            case "Mechanical Neuroimaging Lab":
                setData(MNLPortfolio);
                break;
            default:
                setData(oneTrustPortfolio); 
        }

    }, [selected]);


    return (
        <div className="portfolio" id="portfolio">
            <h1>Portfolio &amp; Experience</h1>
            <ul>
                {list.map(item=> (
                    <PortfolioList title={item.title} active={selected === item.id} setSelected={setSelected} id={item.id}/>
                ))}
            </ul>
        {data.map((d) => (
        <div className={d.divName}>
                <div className="box">
                    <h2>{d.title}</h2>
                    <h3 className="role">{d.role}</h3>
                    <h3 className="published">{d.published}</h3>
                    <p>{d.text}</p>
                        <hr></hr>
                    <h3 className="tech">Technologies: <span>{d.tech}</span></h3>
                    <h3 className="skills">Skills: <span>{d.skills}</span></h3>
                </div>
                <div className="imageSpace">
                    <img src={d.img} alt=""
                    />
                    <div className="link">
                        <a href={d.link} target="_blank">
                        <img src="assets/link.png" alt=""/>
                       </a>
                        </div>
                </div>
        </div>      
        ))}
    <div className="skills">
    <a href="#skills">
                <li>Skills</li>
                <li><KeyboardArrowDown className="icon"/></li>
            </a> 
    </div>
     </div>
    );
}

export default Portfolio
